<template>
  <scroll-container class="pres-page-container m-0">
    <quote-presentation
      ref="body"
      v-if="!waiting && id"
      :trackChanges="true"
      :id="id"
      @selected="selectedHandler"
      @changes="(ch) => (changes = ch)"
      @is-dirty="(b) => (isDirty = b)"
    />

    <container v-else-if="!loading">
      <card-section>
        <card>
          <template #icon>
            <font-awesome-icon icon="check" />
          </template>
          <template #title>Nothing left to approve!</template>
          <p class="mb-4">There is currently nothing to approve on this project.</p>

          <btn-group>
            <btn severity="primary-black" size="lg" @click="() => $store.dispatch('to', 'project')">
              <template #icon>
                <font-awesome-icon icon="arrow-left" />
              </template>
              Go back to project dashboard
            </btn>
          </btn-group>
        </card>
      </card-section>
    </container>

    <spinner v-else size="4em" :loading="1" style="margin: auto auto" />
  </scroll-container>
</template>

<script>
import QuotePresentation from '../quote/presentation/QuotePresentation.vue'
import PageMixin from '../mixins/Page'
import PublicGatewayPage from '../mixins/PublicGatewayPage'

export default {
  mixins: [PageMixin, PublicGatewayPage],
  data() {
    return {
      isDirty: 0,
      changes: null,
      quoteSelected: null,
      waiting: 1,
      loading: 1
    }
  },
  computed: {
    id() {
      return this.$route.params.id || this.$store.state.session.scope.quote
    },
    canSign() {
      return (
        !this.quoteSelected ||
        this.quoteSelected.quote_is_change_order ||
        this.quoteSelected.quote_status === 'p'
      )
    }
  },
  methods: {
    selectedHandler() {
      if (this.$refs.body && this.$refs.body.selected) {
        this.quoteSelected = this.$refs.body && this.$refs.body.cast()

        c.throttle(
          () => {
            this.markSeen()
          },
          { delay: 7000 }
        )
      }
    },
    markSeen() {
      return this.$store.dispatch('ChangeOrder/markMultiple', {
        markAs: 'seen',
        selected: [
          {
            type: 'change_order',
            change_order_id: this.$refs.body.getField('change_order_id')
          }
        ],
        go: false,
        alert: false
      })
    }
  },
  mounted() {
    this.loading = 1
    setTimeout(() => {
      this.waiting = 0
      this.loading = 0
    }, 700)
  },

  components: { QuotePresentation }
}
</script>

<style>
.pres-page-container .scroll-container--inner {
  margin-bottom: 0 !important;
}
</style>
